'use strict';

import * as ActionTypes from '../constants/actionTypes';

import {
    fetchReducer,
    mutationReducer,
    addOrReplaceIn,
    removeIn
} from './mutators';

const initState = {
    isFetching: false,
    isProcessing: false,
    errors: {},

    shops: [],
    pagination: {},

    currentShopTrade: undefined,
    previousShop: undefined,
    shop: undefined,
    shopClaimed: undefined,

    affiliations: [],
    affiliationErrors: undefined,

    specialities: [],
    labels: []
};

export default function shopReducer(state = initState, action) {
    switch (action.type) {
        case ActionTypes.SHOP_FETCH_INIT:
        case ActionTypes.SHOP_FETCH_SUCCESS:
        case ActionTypes.SHOP_FETCH_ERROR:
            if (action.errors) {
                state.shop = undefined;
                state.shops = [];
            }

            return fetchReducer(state, action, (state) => {
                state.shops = action.shops;
            });

        case ActionTypes.SHOP_CHANGE_INIT:
        case ActionTypes.SHOP_CHANGE_SUCCESS:
        case ActionTypes.SHOP_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                state.shop = action.shop;
            });

        case ActionTypes.SHOP_TYPE_CHANGE:
            state.currentShopTrade = action.newTrade;
            return state;

        case ActionTypes.SHOP_PREVIOUS_SUCCESS:
            state.currentShopTrade = action.shopTrade;
            state.previousShop = action.previousShop;
            return state;

        case ActionTypes.SHOP_CLAIM_SUCCESS:
            state.shopClaimed = action.shopClaimed;
            return state;

        case ActionTypes.SHOP_SPECIALITY_FETCH_INIT:
        case ActionTypes.SHOP_SPECIALITY_FETCH_SUCCESS:
        case ActionTypes.SHOP_SPECIALITY_FETCH_ERROR:
            return fetchReducer(state, action, (state) => {
                state.specialities = action.specialities || [];
            });
        case ActionTypes.SHOP_SPECIALITY_CHANGE_INIT:
        case ActionTypes.SHOP_SPECIALITY_CHANGE_SUCCESS:
        case ActionTypes.SHOP_SPECIALITY_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                if (action.removedId) {
                    removeIn(state.specialities, action.removedId);
                } else {
                    addOrReplaceIn(state.specialities, action.speciality);
                }
            });

        case ActionTypes.SHOP_LABEL_FETCH_INIT:
        case ActionTypes.SHOP_LABEL_FETCH_SUCCESS:
        case ActionTypes.SHOP_LABEL_FETCH_ERROR:
            return fetchReducer(state, action, (state) => {
                state.labels = action.labels || [];
            });
        case ActionTypes.SHOP_LABEL_CHANGE_INIT:
        case ActionTypes.SHOP_LABEL_CHANGE_SUCCESS:
        case ActionTypes.SHOP_LABEL_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                if (action.removedId) {
                    removeIn(state.labels, action.removedId);
                } else {
                    addOrReplaceIn(state.labels, action.label);
                }
            });

        default:
            return state;
    }
}

'use strict';

import * as ActionTypes from '../constants/actionTypes';

import {
    fetchReducer,
    mutationReducer
} from './mutators';

const initState = {
    // Required for fetchReducer/mutationReducer
    isFetching: false,
    isProcessing: false,
    errors: {},

    currentId: window.currentUserId ? parseInt(window.currentUserId, 10) : undefined,
    isConnected: !!window.currentUserId,

    user: undefined,

    users: [],
    pagination: {},

    comments: [],
    commentPagination: {},

    activities: [],
    activityPagination: {},

    recentRides: [],
    recentShops: [],
    recentProducts: []
};

export default function userReducer(state = initState, action) {
    switch (action.type) {
        case ActionTypes.USER_FETCH_INIT:
        case ActionTypes.USER_FETCH_SUCCESS:
        case ActionTypes.USER_FETCH_ERROR:
            if (action.errors) {
                state.user = undefined;
            }

            return fetchReducer(state, action, (state) => {
                if (action.user) {
                    state.user = action.user;
                } else {
                    state.users = action.users;
                }
            });

        case ActionTypes.USER_CHANGE_INIT:
        case ActionTypes.USER_CHANGE_SUCCESS:
        case ActionTypes.USER_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                state.user = action.user;
            });

        case ActionTypes.USER_RECENT_FETCH_SUCCESS:
            state.recentRides = action.recentRides;
            state.recentShops = action.recentShops;
            state.recentProducts = action.recentProducts;
            return state;
        case ActionTypes.USER_COMMENT_FETCH_SUCCESS:
            state.comments = action.comments;
            state.commentPagination = action.pagination;
            return state;
        case ActionTypes.USER_ACTIVITY_FETCH_SUCCESS:
            state.activities = action.activities;
            state.activityPagination = action.pagination;
            return state;

        default:
            return state;
    }
}

'use strict';

import * as ActionTypes from '../constants/actionTypes';

import {
    fetchReducer,
    mutationReducer,
    addOrReplaceIn,
    removeIn
} from './mutators';

// Blog
const initBlogState = {
    // Required for fetchReducer/mutationReducer
    isFetching: false,
    isProcessing: false,
    errors: {},

    blog: undefined,

    blogArticles: [],
    pagination: undefined,

    tags: []
};

export function blogReducer(state = initBlogState, action) {
    switch (action.type) {
        case ActionTypes.BLOG_FETCH_INIT:
        case ActionTypes.BLOG_FETCH_ERROR:
        case ActionTypes.BLOG_FETCH_SUCCESS:
            if (action.errors) {
                state.blog = undefined;
                state.blogArticles = [];
            }

            return fetchReducer(state, action, (state) => {
                state.blog = action.blog;
                state.blogArticles = action.blog?.articles;
            });

        case ActionTypes.BLOG_TAGS_FETCH_SUCCESS:
            state.tags = action.tags;
            return state;

        case ActionTypes.BLOG_ARTICLE_CHANGE_SUCCESS:
            if (action.removedId) {
                removeIn(state.blogArticles, action.blogArticle);
            } else {
                addOrReplaceIn(state.blogArticles, action.blogArticle);
            }
            return state;

        default:
            return state;
    }
}

// Blog article
const initBlogArticleState = {
    // Required for fetchReducer/mutationReducer
    isFetching: false,
    isProcessing: false,
    errors: {},
    pagination: undefined,

    blogArticle: undefined,
    isEditing: false
};

export function blogArticleReducer(state = initBlogArticleState, action) {
    switch (action.type) {
        case ActionTypes.BLOG_ARTICLE_FETCH_INIT:
        case ActionTypes.BLOG_ARTICLE_FETCH_SUCCESS:
        case ActionTypes.BLOG_ARTICLE_FETCH_ERROR:
            return fetchReducer(state, action, (state) => {
                state.blogArticle = action.blogArticle;
            });

        case ActionTypes.BLOG_ARTICLE_EDITION_TOGGLE:
            state.isEditing = !state.isEditing;
            return state;

        case ActionTypes.BLOG_ARTICLE_CHANGE_INIT:
        case ActionTypes.BLOG_ARTICLE_CHANGE_SUCCESS:
        case ActionTypes.BLOG_ARTICLE_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                state.blogArticle = action.blogArticle;
                state.isEditing = !action.blogArticle && !action.removedId;
            });

        default:
            return state;
    }
}

'use strict';

import * as ActionTypes from '../constants/actionTypes';

import {
    fetchReducer,
    mutationReducer,
    addOrReplaceIn,
    removeIn
} from './mutators';

const initState = {
    // Required for fetchReducer/mutationReducer
    isFetching: false,
    isProcessing: false,
    errors: {},

    pois: [],
    pagination: {},

    isOrdering: false
};

export default function poiReducer(state = initState, action) {
    switch (action.type) {
        case ActionTypes.POI_FETCH_INIT:
        case ActionTypes.POI_FETCH_SUCCESS:
        case ActionTypes.POI_FETCH_ERROR:
            if (action.errors) {
                state.pois = [];
            }

            return fetchReducer(state, action, (state) => {
                state.pois = action.pois;
            });

        case ActionTypes.POI_CHANGE_INIT:
        case ActionTypes.POI_CHANGE_SUCCESS:
        case ActionTypes.POI_CHANGE_ERROR:
            return mutationReducer(state, action, (state) => {
                if (action.pois) {
                    state.pois = action.pois;
                } else if (action.removedId) {
                    removeIn(state.pois, action.removedId);
                } else {
                    addOrReplaceIn(state.pois, action.poi);
                }
            });

        case ActionTypes.POI_ORDER_TOGGLE:
            state.isOrdering = action.isOrdering;
            return state;

        default:
            return state;
    }
}

'use strict';

// import {
//     Provider
// } from 'react-redux';

import {
    configureStore
} from '../../stores';

import {
    synchronizeBookmarks
} from '../../actions';

// Load Login and Signup modals
import ConnectionLoader from '../../loaders/connection';

if (Utils.isEmpty(window.currentUserId)) {
    setTimeout(() => {
        ConnectionLoader()
            .then(({
                       Login,
                       Signup
                   }) => {
                const signupElement = document.getElementById('signup-modal-component');
                const loginElement = document.getElementById('login-modal-component');

                if (!signupElement || !loginElement) {
                    return;
                }

                const signupRoot = ReactCreateRoot(signupElement);
                signupRoot.render(
                    <Signup launcherClass="signup-link"/>
                );

                const loginRoot = ReactCreateRoot(loginElement);
                loginRoot.render(
                    <Login launcherClass="login-link"/>
                );
            });
    }, 100);
}

// Synchronize local data with user account just after sign in ou up
if (window.firstConnection) {
    configureStore.dispatch(synchronizeBookmarks());
}

// Comment chat for now: not used
// // Chat minimized
// import MinifiedChat from '../users/chat/minified';
// const root = ReactCreateRoot(document.getElementById('chat-component'));
// root.render(
//     <Provider store={configureStore}>
//         <MinifiedChat/>
//     </Provider>
// );


'use strict';

export const USER = 'USER';
export const USER_FETCH_INIT = 'USER/FETCH_INIT';
export const USER_FETCH_SUCCESS = 'USER/FETCH_SUCCESS';
export const USER_FETCH_ERROR = 'USER/FETCH_ERROR';
export const USER_CHANGE_INIT = 'USER/CHANGE_INIT';
export const USER_CHANGE_SUCCESS = 'USER/CHANGE_SUCCESS';
export const USER_CHANGE_ERROR = 'USER/CHANGE_ERROR';
export const USER_COMMENT_FETCH_SUCCESS = 'USER/COMMENT_FETCH_SUCCESS';
export const USER_ACTIVITY_FETCH_SUCCESS = 'USER/ACTIVITY_FETCH_SUCCESS';
export const USER_RECENT_FETCH_SUCCESS = 'USER/RECENT_FETCH_SUCCESS';

export const BOOKMARK_ADD = 'BOOKMARK/ADD';
export const BOOKMARK_DELETE = 'BOOKMARK/DELETE';

export const RIDE = 'RIDE';
export const RIDE_FETCH_INIT = 'RIDE/FETCH_INIT';
export const RIDE_FETCH_SUCCESS = 'RIDE/FETCH_SUCCESS';
export const RIDE_FETCH_ERROR = 'RIDE/FETCH_ERROR';
export const RIDE_TYPE_CHANGE = 'RIDE/TYPE_CHANGE';
export const RIDE_PREVIOUS_SUCCESS = 'RIDE/PREVIOUS_SUCCESS';
export const RIDE_CHANGE_INIT = 'RIDE/CHANGE_INIT';
export const RIDE_CHANGE_SUCCESS = 'RIDE/CHANGE_SUCCESS';
export const RIDE_CHANGE_ERROR = 'RIDE/CHANGE_ERROR';
export const RIDE_JOB_PROGRESS = 'RIDE/JOB_PROGRESS';

export const RIDE_THEME = 'RIDE_THEME';
export const RIDE_THEME_FETCH_INIT = 'RIDE_THEME/FETCH_INIT';
export const RIDE_THEME_FETCH_SUCCESS = 'RIDE_THEME/FETCH_SUCCESS';
export const RIDE_THEME_FETCH_ERROR = 'RIDE_THEME/FETCH_ERROR';
export const RIDE_THEME_CHANGE_INIT = 'RIDE_THEME/CHANGE_INIT';
export const RIDE_THEME_CHANGE_SUCCESS = 'RIDE_THEME/CHANGE_SUCCESS';
export const RIDE_THEME_CHANGE_ERROR = 'RIDE_THEME/CHANGE_ERROR';

export const RIDE_EQUIPMENT = 'RIDE_EQUIPMENT';
export const RIDE_EQUIPMENT_FETCH_INIT = 'RIDE_EQUIPMENT/FETCH_INIT';
export const RIDE_EQUIPMENT_FETCH_SUCCESS = 'RIDE_EQUIPMENT/FETCH_SUCCESS';
export const RIDE_EQUIPMENT_FETCH_ERROR = 'RIDE_EQUIPMENT/FETCH_ERROR';
export const RIDE_EQUIPMENT_CHANGE_INIT = 'RIDE_EQUIPMENT/CHANGE_INIT';
export const RIDE_EQUIPMENT_CHANGE_SUCCESS = 'RIDE_EQUIPMENT/CHANGE_SUCCESS';
export const RIDE_EQUIPMENT_CHANGE_ERROR = 'RIDE_EQUIPMENT/CHANGE_ERROR';

export const POI = 'POI';
export const POI_FETCH_INIT = 'POI/FETCH_INIT';
export const POI_FETCH_SUCCESS = 'POI/FETCH_SUCCESS';
export const POI_FETCH_ERROR = 'POI/FETCH_ERROR';
export const POI_CHANGE_INIT = 'POI/CHANGE_INIT';
export const POI_CHANGE_SUCCESS = 'POI/CHANGE_SUCCESS';
export const POI_CHANGE_ERROR = 'POI/CHANGE_ERROR';
export const POI_ORDER_TOGGLE = 'POI/ORDER_TOGGLE';

export const SHOP = 'SHOP';
export const SHOP_FETCH_INIT = 'SHOP/FETCH_INIT';
export const SHOP_FETCH_SUCCESS = 'SHOP/FETCH_SUCCESS';
export const SHOP_FETCH_ERROR = 'SHOP/FETCH_ERROR';
export const SHOP_TYPE_CHANGE = 'SHOP/TYPE_CHANGE';
export const SHOP_PREVIOUS_SUCCESS = 'SHOP/PREVIOUS_SUCCESS';
export const SHOP_CHANGE_INIT = 'SHOP/CHANGE_INIT';
export const SHOP_CHANGE_SUCCESS = 'SHOP/CHANGE_SUCCESS';
export const SHOP_CHANGE_ERROR = 'SHOP/CHANGE_ERROR';
export const SHOP_CLAIM_SUCCESS = 'SHOP/CLAIM_SUCCESS';

export const SHOP_SPECIALITY = 'SHOP_SPECIALITY';
export const SHOP_SPECIALITY_FETCH_INIT = 'SHOP_SPECIALITY/FETCH_INIT';
export const SHOP_SPECIALITY_FETCH_SUCCESS = 'SHOP_SPECIALITY/FETCH_SUCCESS';
export const SHOP_SPECIALITY_FETCH_ERROR = 'SHOP_SPECIALITY/FETCH_ERROR';
export const SHOP_SPECIALITY_CHANGE_INIT = 'SHOP_SPECIALITY/CHANGE_INIT';
export const SHOP_SPECIALITY_CHANGE_SUCCESS = 'SHOP_SPECIALITY/CHANGE_SUCCESS';
export const SHOP_SPECIALITY_CHANGE_ERROR = 'SHOP_SPECIALITY/CHANGE_ERROR';

export const SHOP_LABEL = 'SHOP_LABEL';
export const SHOP_LABEL_FETCH_INIT = 'SHOP_LABEL/FETCH_INIT';
export const SHOP_LABEL_FETCH_SUCCESS = 'SHOP_LABEL/FETCH_SUCCESS';
export const SHOP_LABEL_FETCH_ERROR = 'SHOP_LABEL/FETCH_ERROR';
export const SHOP_LABEL_CHANGE_INIT = 'SHOP_LABEL/CHANGE_INIT';
export const SHOP_LABEL_CHANGE_SUCCESS = 'SHOP_LABEL/CHANGE_SUCCESS';
export const SHOP_LABEL_CHANGE_ERROR = 'SHOP_LABEL/CHANGE_ERROR';

export const PRODUCT = 'PRODUCT';
export const PRODUCT_FETCH_INIT = 'PRODUCT/FETCH_INIT';
export const PRODUCT_FETCH_SUCCESS = 'PRODUCT/FETCH_SUCCESS';
export const PRODUCT_FETCH_ERROR = 'PRODUCT/FETCH_ERROR';
export const PRODUCT_PREVIOUS_SUCCESS = 'PRODUCT/PREVIOUS_SUCCESS';
export const PRODUCT_CHANGE_INIT = 'PRODUCT/CHANGE_INIT';
export const PRODUCT_CHANGE_SUCCESS = 'PRODUCT/CHANGE_SUCCESS';
export const PRODUCT_CHANGE_ERROR = 'PRODUCT/CHANGE_ERROR';

export const PRODUCT_CRAFT = 'PRODUCT_CRAFT';
export const PRODUCT_CRAFT_FETCH_INIT = 'PRODUCT_CRAFT/FETCH_INIT';
export const PRODUCT_CRAFT_FETCH_SUCCESS = 'PRODUCT_CRAFT/FETCH_SUCCESS';
export const PRODUCT_CRAFT_FETCH_ERROR = 'PRODUCT_CRAFT/FETCH_ERROR';
export const PRODUCT_CRAFT_CHANGE_INIT = 'PRODUCT_CRAFT/CHANGE_INIT';
export const PRODUCT_CRAFT_CHANGE_SUCCESS = 'PRODUCT_CRAFT/CHANGE_SUCCESS';
export const PRODUCT_CRAFT_CHANGE_ERROR = 'PRODUCT_CRAFT/CHANGE_ERROR';

export const PRODUCT_MATERIAL = 'PRODUCT_MATERIAL';
export const PRODUCT_MATERIAL_FETCH_INIT = 'PRODUCT_MATERIAL/FETCH_INIT';
export const PRODUCT_MATERIAL_FETCH_SUCCESS = 'PRODUCT_MATERIAL/FETCH_SUCCESS';
export const PRODUCT_MATERIAL_FETCH_ERROR = 'PRODUCT_MATERIAL/FETCH_ERROR';
export const PRODUCT_MATERIAL_CHANGE_INIT = 'PRODUCT_MATERIAL/CHANGE_INIT';
export const PRODUCT_MATERIAL_CHANGE_SUCCESS = 'PRODUCT_MATERIAL/CHANGE_SUCCESS';
export const PRODUCT_MATERIAL_CHANGE_ERROR = 'PRODUCT_MATERIAL/CHANGE_ERROR';

export const PRODUCT_CRAFTS_SPECIALITY = 'PRODUCT_CRAFTS_SPECIALITY';
export const PRODUCT_CRAFTS_SPECIALITY_FETCH_INIT = 'PRODUCT_CRAFTS_SPECIALITY/FETCH_INIT';
export const PRODUCT_CRAFTS_SPECIALITY_FETCH_SUCCESS = 'PRODUCT_CRAFTS_SPECIALITY/FETCH_SUCCESS';
export const PRODUCT_CRAFTS_SPECIALITY_FETCH_ERROR = 'PRODUCT_CRAFTS_SPECIALITY/FETCH_ERROR';
export const PRODUCT_CRAFTS_SPECIALITY_CHANGE_INIT = 'PRODUCT_CRAFTS_SPECIALITY/CHANGE_INIT';
export const PRODUCT_CRAFTS_SPECIALITY_CHANGE_SUCCESS = 'PRODUCT_CRAFTS_SPECIALITY/CHANGE_SUCCESS';
export const PRODUCT_CRAFTS_SPECIALITY_CHANGE_ERROR = 'PRODUCT_CRAFTS_SPECIALITY/CHANGE_ERROR';

export const PRODUCT_MATERIALS_CRAFT = 'PRODUCT_MATERIALS_CRAFT';
export const PRODUCT_MATERIALS_CRAFT_FETCH_INIT = 'PRODUCT_MATERIALS_CRAFT/FETCH_INIT';
export const PRODUCT_MATERIALS_CRAFT_FETCH_SUCCESS = 'PRODUCT_MATERIALS_CRAFT/FETCH_SUCCESS';
export const PRODUCT_MATERIALS_CRAFT_FETCH_ERROR = 'PRODUCT_MATERIALS_CRAFT/FETCH_ERROR';
export const PRODUCT_MATERIALS_CRAFT_CHANGE_INIT = 'PRODUCT_MATERIALS_CRAFT/CHANGE_INIT';
export const PRODUCT_MATERIALS_CRAFT_CHANGE_SUCCESS = 'PRODUCT_MATERIALS_CRAFT/CHANGE_SUCCESS';
export const PRODUCT_MATERIALS_CRAFT_CHANGE_ERROR = 'PRODUCT_MATERIALS_CRAFT/CHANGE_ERROR';

export const AFFILIATION = 'AFFILIATION';
export const AFFILIATION_CHANGE_INIT = 'AFFILIATION/CHANGE_INIT';
export const AFFILIATION_CHANGE_SUCCESS = 'AFFILIATION/CHANGE_SUCCESS';
export const AFFILIATION_CHANGE_ERROR = 'AFFILIATION/CHANGE_ERROR';

export const COMMENT = 'COMMENT';
export const COMMENT_FETCH_INIT = 'COMMENT/FETCH_INIT';
export const COMMENT_FETCH_SUCCESS = 'COMMENT/FETCH_SUCCESS';
export const COMMENT_FETCH_ERROR = 'COMMENT/FETCH_ERROR';
export const COMMENT_CHANGE_INIT = 'COMMENT/CHANGE_INIT';
export const COMMENT_CHANGE_SUCCESS = 'COMMENT/CHANGE_SUCCESS';
export const COMMENT_CHANGE_ERROR = 'COMMENT/CHANGE_ERROR';

export const SEARCH_AUTOCOMPLETE = 'SEARCH_AUTOCOMPLETE';
export const SEARCH_AUTOCOMPLETE_FETCH_INIT = 'SEARCH_AUTOCOMPLETE/FETCH_INIT';
export const SEARCH_AUTOCOMPLETE_FETCH_ERROR = 'SEARCH_AUTOCOMPLETE/FETCH_FAILED';
export const SEARCH_AUTOCOMPLETE_FETCH_SUCCESS = 'SEARCH_AUTOCOMPLETE/FETCH_SUCCESS';

export const SEARCH_FETCH_INIT = 'SEARCH/FETCH_INIT';
export const SEARCH_FETCH_ERROR = 'SEARCH/FETCH_FAILED';
export const SEARCH_FETCH_SUCCESS = 'SEARCH/FETCH_SUCCESS';
export const SEARCH_ITEM_CLICK = 'SEARCH/ITEM_CLICK';
export const SEARCH_ITEM_OVER = 'SEARCH/ITEM_OVER';
export const SEARCH_ITEM_OUT = 'SEARCH/ITEM_OUT';
export const SEARCH_AROUND_ME_SUCCESS = 'SEARCH/AROUND_ME_SUCCESS';
export const SEARCH_RELATED_SUCCESS = 'SEARCH/RELATED_SUCCESS';
export const SEARCH_FILTERS_TOGGLE = 'SEARCH/FILTERS_TOGGLE';
export const SEARCH_POPULAR_SUCCESS = 'SEARCH/POPULAR_SUCCESS';
export const SEARCH_AFFILIATION_SUCCESS = 'SEARCH/AFFILIATION_SUCCESS';
export const SEARCH_META_SUCCESS = 'SEARCH/META_SUCCESS';

export const MAP_MAP_LOAD = 'MAP/MAP_LOAD';
export const MAP_MAP_LOADED = 'MAP/MAP_LOADED';
export const MAP_MARKER_CHANGED = 'MAP/MARKER_CHANGED';
export const MAP_MARKER_CLICKED = 'MAP/MARKER_CLICKED';
export const MAP_MARKER_LINK_CLICKED = 'MAP/MARKER_LINK_CLICKED';
export const MAP_MARKER_POPUP_CLOSED = 'MAP/MARKER_POPUP_CLOSED';
export const MAP_MARKER_CENTER = 'MAP/MARKER_CENTER';
export const MAP_MARKER_SELECT = 'MAP/MARKER_SELECT';
export const MAP_HIGHLIGHT_POIS = 'MAP/HIGHLIGHT_POIS';
export const MAP_EXPLORE_SEARCHED = 'MAP/EXPLORE_SEARCHED';
export const MAP_AFFILIATED_CHANGED = 'MAP/AFFILIATED_CHANGED';
export const MAP_POI_LOADED = 'MAP/POI_LOADED';
export const MAP_POI_PICTURE_CLICKED = 'MAP/POI_PICTURE_CLICKED';
export const MAP_POI_PICTURE_CHANGE = 'MAP/POI_PICTURE_CHANGE';
export const MAP_CHOROPLETH_LOADED = 'MAP/CHOROPLETH_LOADED';
export const MAP_CHOROPLETH_DEPARTMENT_CLICKED = 'MAP/CHOROPLETH_DEPARTMENT_CLICKED';
export const MAP_CHART_LOAD = 'MAP/CHART_LOAD';
export const MAP_LOCATION_GEOLOCATE = 'MAP/LOCATION_GEOLOCATE';
export const MAP_LOCATION_GEOLOCATED = 'MAP/LOCATION_GEOLOCATED';
export const MAP_LOCATION_TRACK = 'MAP/LOCATION_TRACK';
export const MAP_LOCATION_UNTRACK = 'MAP/LOCATION_UNTRACK';
export const MAP_ANIMATE_TRACK = 'MAP/MAP_ANIMATE_TRACK';
export const MAP_COORDINATES_INIT = 'MAP/COORDINATES_INIT';
export const MAP_COORDINATES_FOUND = 'MAP/COORDINATES_FOUND';
export const MAP_ADDRESS_INIT = 'MAP/ADDRESS_INIT';
export const MAP_ADDRESS_FOUND = 'MAP/ADDRESS_FOUND';
export const MAP_CITY_INIT = 'MAP/CITY_INIT';
export const MAP_CITY_FOUND = 'MAP/CITY_FOUND';

export const BLOG = 'BLOG';
export const BLOG_FETCH_INIT = 'BLOG/FETCH_INIT';
export const BLOG_FETCH_SUCCESS = 'BLOG/FETCH_SUCCESS';
export const BLOG_FETCH_ERROR = 'BLOG/FETCH_ERROR';
export const BLOG_TAGS_FETCH_SUCCESS = 'BLOG/TAGS_FETCH_SUCCESS';

export const BLOG_ARTICLE = 'BLOG_ARTICLE';
export const BLOG_ARTICLE_FETCH_INIT = 'BLOG_ARTICLE/FETCH_INIT';
export const BLOG_ARTICLE_FETCH_SUCCESS = 'BLOG_ARTICLE/FETCH_SUCCESS';
export const BLOG_ARTICLE_FETCH_ERROR = 'BLOG_ARTICLE/FETCH_ERROR';
export const BLOG_ARTICLE_CHANGE_INIT = 'BLOG_ARTICLE/CHANGE_INIT';
export const BLOG_ARTICLE_CHANGE_SUCCESS = 'BLOG_ARTICLE/CHANGE_SUCCESS';
export const BLOG_ARTICLE_CHANGE_ERROR = 'BLOG_ARTICLE/CHANGE_ERROR';
export const BLOG_ARTICLE_EDITION_TOGGLE = 'BLOG_ARTICLE/EDITION_TOGGLE';

export const CHAT_ROOM = 'CHAT_ROOM';
export const CHAT_ROOM_BOX_EXPAND = 'CHAT_ROOM/BOX_EXPAND';
export const CHAT_ROOM_FETCH_INIT = 'CHAT_ROOM/FETCH_INIT';
export const CHAT_ROOM_FETCH_SUCCESS = 'CHAT_ROOM/FETCH_SUCCESS';
export const CHAT_ROOM_FETCH_ERROR = 'CHAT_ROOM/FETCH_ERROR';
export const CHAT_ROOM_CHANGE_INIT = 'CHAT_ROOM/CHANGE_INIT';
export const CHAT_ROOM_CHANGE_SUCCESS = 'CHAT_ROOM/CHANGE_SUCCESS';
export const CHAT_ROOM_CHANGE_ERROR = 'CHAT_ROOM/CHANGE_ERROR';
export const CHAT_ROOM_BOX_INACTIVE = 'CHAT_ROOM/BOX_INACTIVE';

export const CHAT_MESSAGE = 'CHAT_MESSAGE';
export const CHAT_MESSAGE_FETCH_INIT = 'CHAT_MESSAGE/FETCH_INIT';
export const CHAT_MESSAGE_FETCH_SUCCESS = 'CHAT_MESSAGE/FETCH_SUCCESS';
export const CHAT_MESSAGE_FETCH_ERROR = 'CHAT_MESSAGE/FETCH_ERROR';
export const CHAT_MESSAGE_CHANGE_INIT = 'CHAT_MESSAGE/CHANGE_INIT';
export const CHAT_MESSAGE_CHANGE_SUCCESS = 'CHAT_MESSAGE/CHANGE_SUCCESS';
export const CHAT_MESSAGE_CHANGE_ERROR = 'CHAT_MESSAGE/CHANGE_ERROR';
export const CHAT_MESSAGE_NO_MESSAGE_SUCCESS = 'CHAT_MESSAGE_NO_MESSAGE_SUCCESS';

export const CONVERSATION = 'CONVERSATION';
export const CONVERSATION_FETCH_INIT = 'CONVERSATION/FETCH_INIT';
export const CONVERSATION_FETCH_SUCCESS = 'CONVERSATION/FETCH_SUCCESS';
export const CONVERSATION_FETCH_ERROR = 'CONVERSATION/FETCH_ERROR';
export const CONVERSATION_CHANGE_INIT = 'CONVERSATION/CHANGE_INIT';
export const CONVERSATION_CHANGE_SUCCESS = 'CONVERSATION/CHANGE_SUCCESS';
export const CONVERSATION_CHANGE_ERROR = 'CONVERSATION/CHANGE_ERROR';

export const ADMIN_VALIDATION_PENDING_FETCH_SUCCESS = 'ADMIN/VALIDATION_PENDING_FETCH_SUCCESS';
export const ADMIN_COMMENT_DELETION_PENDING_FETCH_SUCCESS = 'ADMIN/COMMENT_DELETION_PENDING_FETCH_SUCCESS';
export const ADMIN_SETTINGS_CHANGE_SUCCESS = 'ADMIN/SETTINGS_CHANGE_SUCCESS';
export const ADMIN_SETTINGS_CHANGE_ERROR = 'ADMIN/SETTINGS_CHANGE_ERROR';
export const ADMIN_CACHE_FLUSH_SUCCESS = 'ADMIN/CACHE_FLUSH_SUCCESS';
export const ADMIN_DATABASE_DUMP_SUCCESS = 'ADMIN/DATABASE_DUMP_SUCCESS';
export const ADMIN_SEO_SITEMAP_GENERATE_SUCCESS = 'ADMIN/SEO_SITEMAP_GENERATE_SUCCESS';
export const ADMIN_SEO_SLUG_GENERATE_SUCCESS = 'ADMIN/SEO_SLUG_GENERATE_SUCCESS';

export const CONTACT = 'CONTACT';
export const CONTACT_FETCH_INIT = 'CONTACT/FETCH_INIT';
export const CONTACT_FETCH_SUCCESS = 'CONTACT/FETCH_SUCCESS';
export const CONTACT_FETCH_ERROR = 'CONTACT/FETCH_ERROR';
export const CONTACT_EDIT = 'CONTACT_EDIT';
export const CONTACT_CHANGE_INIT = 'CONTACT/CHANGE_INIT';
export const CONTACT_CHANGE_SUCCESS = 'CONTACT/CHANGE_SUCCESS';
export const CONTACT_CHANGE_ERROR = 'CONTACT/CHANGE_ERROR';
export const CONTACT_CATEGORIES_FETCH_SUCCESS = 'CONTACT/CATEGORIES_FETCH_SUCCESS';

export const TOURIST_OFFICE = 'TOURIST_OFFICE';
export const TOURIST_OFFICE_FETCH_INIT = 'TOURIST_OFFICE/FETCH_INIT';
export const TOURIST_OFFICE_FETCH_SUCCESS = 'TOURIST_OFFICE/FETCH_SUCCESS';
export const TOURIST_OFFICE_FETCH_ERROR = 'TOURIST_OFFICE/FETCH_ERROR';
export const TOURIST_OFFICE_CHANGE_INIT = 'TOURIST_OFFICE/CHANGE_INIT';
export const TOURIST_OFFICE_CHANGE_SUCCESS = 'TOURIST_OFFICE/CHANGE_SUCCESS';
export const TOURIST_OFFICE_CHANGE_ERROR = 'TOURIST_OFFICE/CHANGE_ERROR';

export const PLACE = 'PLACE';
export const PLACE_FETCH_INIT = 'PLACE/FETCH_INIT';
export const PLACE_FETCH_SUCCESS = 'PLACE/FETCH_SUCCESS';
export const PLACE_FETCH_ERROR = 'PLACE/FETCH_ERROR';
export const PLACE_CHANGE_INIT = 'PLACE/CHANGE_INIT';
export const PLACE_CHANGE_SUCCESS = 'PLACE/CHANGE_SUCCESS';
export const PLACE_CHANGE_ERROR = 'PLACE/CHANGE_ERROR';

export const SEO_DATA = 'SEO_DATA';
export const SEO_DATA_FETCH_INIT = 'SEO_DATA/FETCH_INIT';
export const SEO_DATA_FETCH_SUCCESS = 'SEO_DATA/FETCH_SUCCESS';
export const SEO_DATA_FETCH_ERROR = 'SEO_DATA/FETCH_ERROR';
export const SEO_DATA_EDIT = 'SEO_DATA_EDIT';
export const SEO_DATA_CHANGE_INIT = 'SEO_DATA/CHANGE_INIT';
export const SEO_DATA_CHANGE_SUCCESS = 'SEO_DATA/CHANGE_SUCCESS';
export const SEO_DATA_CHANGE_ERROR = 'SEO_DATA/CHANGE_ERROR';

export const OPEN_DATA = 'OPEN_DATA';
export const OPEN_DATA_FETCH_INIT = 'OPEN_DATA/FETCH_INIT';
export const OPEN_DATA_FETCH_SUCCESS = 'OPEN_DATA/FETCH_SUCCESS';
export const OPEN_DATA_FETCH_ERROR = 'OPEN_DATA/FETCH_ERROR';
export const OPEN_DATA_EDIT = 'OPEN_DATA_EDIT';
export const OPEN_DATA_CHANGE_INIT = 'OPEN_DATA/CHANGE_INIT';
export const OPEN_DATA_CHANGE_SUCCESS = 'OPEN_DATA/CHANGE_SUCCESS';
export const OPEN_DATA_CHANGE_ERROR = 'OPEN_DATA/CHANGE_ERROR';

export const IMPORT_PARSE_SUCCESS = 'IMPORT/PARSE_SUCCESS';
export const IMPORT_DELETE_SUCCESS = 'IMPORT/DELETE_SUCCESS';
export const IMPORT_CREATE_SUCCESS = 'IMPORT/CREATE_SUCCESS';
export const IMPORT_DELETE_CREATED_SUCCESS = 'IMPORT/DELETE_CREATED_SUCCESS';
export const IMPORT_COMPLETE_SUCCESS = 'IMPORT/COMPLETE_SUCCESS';

'use strict';

// import PWAManager from './modules/pwaManager';

// Auto polyfill => polyfill are loaded in a dedicated file only if browser doesn't support es6
// require('./polyfills');
if (typeof AbortController === 'undefined') {
    import('abortcontroller-polyfill/dist/polyfill-patch-fetch');
}

// Error reporting
if (GlobalEnvironment.NODE_ENV === 'production') {
    require('./modules/sentry');
}

// jQuery
require('jquery-ujs');

// Define settings
window.settings = JSON.parse(document.getElementById('settings')?.innerText || '{}');

// Materialize
// Imported globally
M.AutoInit();

// // PWA manager
// PWAManager.initialize();

// Configure log level
if (GlobalEnvironment.NODE_ENV !== 'production') {
    require('./modules/debugLog');
    require('./modules/debugHelper');
}

// // Warn user about cookies
// if (GlobalEnvironment.NODE_ENV === 'production') {
//     const cookieChoices = require('./modules/cookieChoices').default;
//
//     setTimeout(() => {
//         cookieChoices.displayCookies(window.termsUrl);
//     }, 2000);
// }

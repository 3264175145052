'use strict';

import '../../../stylesheets/shared/components/_notification.scss';

import {
    NotificationStack
} from 'react-notification';

const Notification = {};


class NotificationComponent extends React.Component {
    constructor(props) {
        super(props);

        this._timeout = null;

        ['success', 'alert', 'warn', 'error'].forEach((type) => {
            Notification[type] = (message, actionButton, actionCallback) => {
                this._handleAdd(type, message, actionButton, actionCallback);
            };
        });
    }

    state = {
        notifications: []
    };

    componentWillUnmount() {
        if (this._timeout) {
            clearTimeout(this._timeout);
        }
    }

    _handleAdd = (level, message, actionButton, actionCallback, time = 10) => {
        const key = Utils.uuid();

        return this.setState({
            notifications: this.state.notifications.concat([{
                message: message,
                key: key,
                action: actionButton,
                className: `notification-${level}`,
                onClick: (deactivate) => {
                    if (typeof deactivate === 'function') {
                        deactivate();
                    }

                    if (typeof actionCallback === 'function') {
                        actionCallback();
                    }

                    this._timeout = setTimeout(() => this.removeNotification(key), 400);
                },
                dismissAfter: time * 1000
            }])
        });
    };

    removeNotification = (key) => {
        this.setState({
            notifications: this.state.notifications.filter((notif) => notif.key !== key)
        });
    };

    render() {
        return (
            <div className="notification">
                <NotificationStack notifications={this.state.notifications}
                                   onDismiss={(notification) => this.removeNotification(notification.key)}/>
            </div>
        );
    }
}

const root = ReactCreateRoot(document.getElementById('notification-component'));
root.render(
    <NotificationComponent/>
);

export const message = Notification;

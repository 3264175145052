'use strict';

import {
    produce
} from 'immer';

import {
    combineReducers
} from 'redux-immer';

import userReducer from './userReducer';
import bookmarkReducer from './bookmarkReducer';
import {
    autocompleteReducer,
    searchReducer
} from './searchReducer';
import {
    mapReducer,
    addressReducer
} from './mapReducer';
import rideReducer from './rideReducer';
import poiReducer from './poiReducer';
import shopReducer from './shopReducer';
import productReducer from './productReducer';
import touristOfficeReducer from './touristOfficeReducer';
import placeReducer from './placeReducer';
import commentReducer from './commentReducer';
import {
    blogReducer,
    blogArticleReducer
} from './blogReducer';
import chatReducer from './chatReducer';
import conversationReducer from './conversationReducer';


export default combineReducers(produce, {
    userState: userReducer,
    bookmarkState: bookmarkReducer,
    autocompleteState: autocompleteReducer,
    searchState: searchReducer,
    mapState: mapReducer,
    addressState: addressReducer,
    rideState: rideReducer,
    poiState: poiReducer,
    shopState: shopReducer,
    productState: productReducer,
    touristOfficeState: touristOfficeReducer,
    placeState: placeReducer,
    commentState: commentReducer,
    blogState: blogReducer,
    blogArticleState: blogArticleReducer,
    chatState: chatReducer,
    conversationState: conversationReducer
});

'use strict';

import * as ActionTypes from '../constants/actionTypes';

import api from '../middlewares/api';

import {
    hasLocalStorage,
    saveLocalData,
    getAllData
} from '../middlewares/localStorage';

// Bookmarks
const receiveBookmark = (json) => ({
    type: ActionTypes.BOOKMARK_ADD,
    bookmark: json.bookmark
});
const deleteBookmark = (json) => ({
    type: ActionTypes.BOOKMARK_DELETE,
    removedBookmarkId: json.bookmark.bookmarkedId,
    bookmark: json.bookmark
});

export const bookmark = (bookmarkedModel, bookmarkedId, isBookmarked) => (dispatch, getState) => {
    const currentUserId = getState().userState.currentId;
    const bookmark = {
        userId: currentUserId,
        bookmarkedModel,
        bookmarkedId
    };

    if (!currentUserId) {
        if (isBookmarked) {
            Notification.alert(I18n.t('js.bookmark.notification.not_connected'));
        } else if (hasLocalStorage) {
            saveLocalData('bookmark', {bookmark});
            Notification.alert(I18n.t('js.bookmark.notification.saved_later'), I18n.t('js.bookmark.notification.connection'), () => {
                window.location = '/login';
            });

            return dispatch(receiveBookmark({bookmark}));
        } else {
            Notification.alert(I18n.t('js.bookmark.notification.not_connected'), I18n.t('js.bookmark.notification.connection'), () => {
                window.location = '/login';
            });
        }

        return false;
    } else if (!isBookmarked) {
        return api
            .post(`/api/users/${currentUserId}/bookmark`, {
                force_locale: window.locale,
                bookmark
            })
            .then((json) => {
                if (!isBookmarked) {
                    Notification.alert(I18n.t('js.bookmark.notification.text'), I18n.t('js.bookmark.notification.link'), () => {
                        if (window.locale === 'fr') {
                            window.location = `/utilisateurs/${currentUserId}/favoris`;
                        } else {
                            window.location = `/users/${currentUserId}/bookmarks`;
                        }
                    });
                }

                return dispatch(receiveBookmark(json));
            });
    } else {
        return api
            .delete(`/api/users/${currentUserId}/bookmark`, {
                force_locale: window.locale,
                bookmark
            })
            .then((json) => dispatch(deleteBookmark(json)));
    }
};

// Synchronize
export const synchronizeBookmarks = () => (dispatch) => {
    const pendingData = getAllData();

    if (Utils.isEmpty(pendingData)) {
        return;
    }

    Object.keys(pendingData)
        .forEach((dataName) => {
            const dataParams = pendingData[dataName];
            if (dataName && dataName === 'bookmark' && dataParams && Array.isArray(dataParams)) {
                dataParams.forEach((bookmarkParams) => {
                    dispatch(bookmark(bookmarkParams.bookmarkedModel, bookmarkParams.bookmarkedId, bookmarkParams.isRemoving));
                });
            }
        });
};

'use strict';

require('../application');
// require('../pwa');

require('../modules/validation');

require('../common/megaMenu');
require('../components/users/account');
require('../components/search/modal');

// Reload page if connected to bypass browser cache
function checkUserState() {
    if (GlobalEnvironment.NODE_ENV !== 'production') {
        return;
    }

    setTimeout(function () {
        if ((Utils.getCookie('user.connected_id') && !window.currentUserId) || (Utils.getCookie('admin.connected_id') && !window.currentAdminId) || (window.currentUserId && !Utils.getCookie('user.connected_id')) || (window.currentAdminId && !Utils.getCookie('admin.connected_id'))) {
            const timestamp = Date.now();
            const urlParams = window.location.search;
            const newUrl = window.location + (urlParams ? urlParams + '&' : '?') + `_=${timestamp}`;
            window.location.replace(newUrl);
        }
    }, 10);
}

if (document.readyState !== 'loading') {
    checkUserState();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        checkUserState();
    });
}

// Clean timestamp after connection
if (window.location.search.includes('_=')) {
    const url = new URL(window.location);
    url.searchParams.delete('_');
    window.history.replaceState(null, null, url);
}

// Initialize all SideNav
const sidenavs = document.querySelectorAll('.sidenav');
M.Sidenav.init(sidenavs, {
    edge: 'left'
});

// Initialize dropdowns
const dropdowns = document.querySelectorAll('.dropdown-trigger');
M.Dropdown.init(dropdowns, {
    coverTrigger: false,
    constrainWidth: false
});

// Flash messages
const flashes = document.querySelectorAll('.loca-flash');
Array.prototype.forEach.call(flashes, function (element) {
    const level = element.getAttribute('data-level');
    const token = element.getAttribute('data-flash-token');

    if (sessionStorage) {
        // Do not display same flash message twice
        if (sessionStorage.getItem(`flash-message-${token}`)) {
            return;
        }
    }

    // Let's the Notification component initialize
    setTimeout(function () {
        if (level === 'success') {
            Notification.success(element.innerHTML);
        } else if (level === 'error') {
            Notification.error(element.innerHTML);
        } else {
            Notification.alert(element.innerHTML);
        }
    }, 600);

    if (sessionStorage) {
        sessionStorage.setItem(`flash-message-${token}`, 'true');
    }
});
